/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Georgia&display=swap");
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item.center {
  z-index: 5;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

html,
body {
  margin: 0;
  font-family: "Georgia serif" !important;
  color: #000a1a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
  font-family: "Georgia serif" !important;
}

p {
  margin: 0;
  font-family: "Georgia serif" !important;
}

.spacer {
  flex: 1;
}

.content {
  display: flex;
  margin: 82px auto 32px;
  padding: 0 16px;
  max-width: 960px;
  flex-direction: column;
  align-items: center;
}

.custom-modal-container .mat-dialog-container {
  padding: 0;
  overflow: hidden;
  max-height: 100vh;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.flexCont {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: flex;
}