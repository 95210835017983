/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Georgia&display=swap");
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

html,
body {
  margin: 0;
  font-family: "Georgia serif" !important;
  color: #000a1a;
}

//  :host {
//     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//     font-size: 14px;
//     color: #333;
//     box-sizing: border-box;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
  font-family: "Georgia serif" !important;
}

p {
  margin: 0;
  font-family: "Georgia serif" !important;
}

.spacer {
  flex: 1;
}

.content {
  display: flex;
  margin: 82px auto 32px;
  padding: 0 16px;
  max-width: 960px;
  flex-direction: column;
  align-items: center;
}

// .card {
//     all: unset;
//     border-radius: 4px;
//     border: 1px solid #eee;
//     background-color: #fafafa;
//     height: 40px;
//     width: 200px;
//     margin: 0 8px 16px;
//     padding: 16px;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     transition: all 0.2s ease-in-out;
//     line-height: 24px;
// }
// .card-container .card:not(:last-child) {
//     margin-right: 0;
// }
// .card.card-small {
//     height: 16px;
//     width: 168px;
// }
// .card-container .card:not(.highlight-card) {
//     cursor: pointer;
// }
// .card-container .card:not(.highlight-card):hover {
//     transform: translateY(-3px);
//     box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
// }
// .card-container .card:not(.highlight-card):hover .material-icons path {
//     fill: rgb(105, 103, 103);
// }
// .card.highlight-card {
//     background-color: #1976d2;
//     color: white;
//     font-weight: 600;
//     border: none;
//     width: auto;
//     min-width: 30%;
//     position: relative;
// }

.custom-modal-container .mat-dialog-container {
  padding: 0;
  overflow: hidden;
  max-height: 100vh;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.flexCont {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  display: flex;
}
